<section class="navbar-links d-flex justify-content-center">
  <ul class="d-flex align-items-center parent m-0">

    <li class="text-center" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <p>Home</p>
    </li>

    <li class="text-center" *ngFor="let item of mainCategoryList" routerLink="/categories/{{ item._id }}"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{ name: item.name }">
      <p>
        {{ item.name }}
      </p>
    </li>
  </ul>
</section>