<section class="navbar-header" *ngIf="screenWidth > 768">
  <p-toast position="bottom-right"></p-toast>

  <ng-template #logoImg>
    <img class="logo-img" src="assets/images/navbar/card4games-voucher cards3.svg" alt="card4games logo" routerLink="/"
      width="294" height="87" />
  </ng-template>

  <ng-template #searchNav>
    <div class="search-input" *ngIf="currentUrl != '/search'">
      <input type="text" (keyup.enter)="searchResult()" [(ngModel)]="searchTerm" class="input-main"
        placeholder="Search" />
      <i class="pi pi-search" (click)="searchResult()"></i>
    </div>
  </ng-template>

  <ng-template #navIcons>
    <div class="d-flex align-items-center">
      <div class="item d-flex align-items-center" (click)="disableCurrency ? false : showDropDownCurrency()"
        *ngIf="!disableCurrency" [ngStyle]="{'cursor': !disableCurrency ? 'pointer' : 'default'}">
        <img width="35" height="11" alt="currency icon" src="assets/images/navbar/currency.svg" />
        <span class="d-flex align-items-center">
          {{ selectedCurrency.key }}
          <i class="pi pi-angle-down" *ngIf="!disableCurrency"></i>
        </span>

        <div class="dropdown-nav" *ngIf="showCurrency" outsideClick (outsideClick)="showCurrency = false">
          <ul class="currency-list list-unstyled">
            <li *ngFor="let item of currencyList" (click)="updateCurrency(item.currency)">
              {{ item.currency }}
              <img [src]="item.icon" width="20" height="20" [alt]="item.currency" />
            </li>
          </ul>
        </div>
      </div>

      <ng-template #auth>
        <div class="icon-item bell" (click)="showDropDownNotification()" [ngClass]="disableCurrency ? 'notAfter' :''">
          <i class="pi pi-bell"></i>
          <!-- <span class="langth" *ngIf="notificationsCount == 0">0</span> -->
          <span class="langth" *ngIf="notificationsCount < 99 && notificationsCount > 0">{{notificationsCount}}</span>
          <span class="langth" *ngIf="notificationsCount >= 99">+{{99}}</span>

          <div class="dropdown-nav" *ngIf="showNotification" outsideClick (outsideClick)="showNotification = false">
            <div class="title d-flex justify-content-between align-items-center mb-2">
              <p>Notifications</p>
              <p class="see-all" routerLink="/settings/notification">See All</p>
            </div>
            <ul class="notification-list list-unstyled" *ngIf="notifications">
              <li *ngFor="let notification of notifications" routerLink="/settings/notification">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="title-noti">
                    {{
                    notification.title && notification.title.length > 10
                    ? (notification.title | slice : 0 : 15) + " ..."
                    : notification.title
                    }}
                  </p>
                  <p class="time-noti">
                    {{ getDate(notification.createdAt) }}
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <p class="content-noti">
                    {{
                    notification.content && notification.content.length > 10
                    ? (notification.content | slice : 0 : 20) + " ..."
                    : notification.content
                    }}
                  </p>
                  <img *ngIf="notification.isRead == false" src="../../../../../assets/images/navbar/new.svg"
                    alt="new message" />
                </div>
              </li>
            </ul>
            <p class="alert-noti" *ngIf="notifications.length == 0">
              You don't have any Notifications yet.
            </p>
          </div>
        </div>
        <div class="icon-item shop" (click)="disableCurrency ? false : showDropDownCard()"
          [ngStyle]="{'cursor': !disableCurrency ? 'pointer' : 'default'}" *ngIf="!disableCurrency">
          <i class="pi pi-shopping-cart"></i>
          <!-- <span class="langth" *ngIf="cartItemsNum == 0"></span> -->
          <span class="langth" *ngIf="cartItemsNum < 99 && cartItemsNum > 0">{{ cartItemsNum }}</span>
          <span class="langth" *ngIf="cartItemsNum >= 99">+{{99 }}</span>
          <div class="dropdown-nav" *ngIf="showCard" outsideClick (outsideClick)="showCard = false">
            <div class="title mb-2">
              <!-- <p *ngIf="cartItemsNum < 99">{{ cartItemsNum }} Cart Items</p>
              <p *ngIf="cartItemsNum >= 99 ">{{ 99 }} Cart Items</p> -->
              <p>{{ cartItemsNum }} Cart Items</p>
            </div>
            <ul class="card-list list-unstyled">
              <li class="d-flex justify-content-between align-items-center my-2"
                *ngFor="let prod of mergedProducts.slice(0,4); let indx = index">
                <div class="d-flex w-75">
                  <img class="img-product" width="56px" height="56" [src]="prod?.icon" [alt]="prod?.name" />
                  <div class="w-100">
                    <span class="d-flex justify-content-between w-100">
                      <span *ngIf="prod.type =='ourProduct'" class="name-product">{{prod.name.slice(0, 8)}}</span>
                      <span *ngIf="prod.type =='sim'" class="name-product">{{prod.name}}</span>
                      <span class="quantity-product">x{{ prod.quantity }}</span>
                    </span>
                    <span class="price-product" *ngIf="prod.type =='ourProduct'">{{ prod.price | number : "1.2" }}{{
                      selectedCurrency.key }}</span>
                    <span class="price-product" *ngIf="prod.type =='sim'">{{ prod.price * prod.quantity | number : "1.2"
                      }}{{ selectedCurrency.key }}</span>
                  </div>
                </div>
                <i *ngIf="!loading" (click)="removeCartItem(prod.id , prod.type)" class="pi pi-trash"></i>
              </li>
              <!-- <li class="d-flex justify-content-between align-items-center my-2"
                *ngFor="let prod of esimData.slice(0,2); let index = index">
                <div class="d-flex w-75">
                  <img class="img-product" width="56px" height="56" [src]="prod?.icon" [alt]="prod?.name" />
                  <div class="w-100">
                    <span class="d-flex justify-content-between w-100">
                      <span class="name-product">{{prod.name}}</span>
                      <span class="quantity-product">x{{ prod.quantity }}</span>
                    </span>
                    <span class="price-product">{{ prod.price | number : "1.2" }} {{ selectedCurrency.key }}</span>
                  </div>
                </div>
                <i (click)="removeCartItem(index , 'sim')" class="pi pi-trash"></i>
              </li> -->
              <hr />
            </ul>
            <div class="totel d-flex my-3 justify-content-between px-1 align-items-center"
              *ngIf="cartItems?.length > 0 || esimData?.length !== 0"> Totel Price <span>
                {{ totalPrice! + totalPriceEsim! | number : "1.2" }}
                {{ selectedCurrency.key }}</span>
            </div>

            <div class="card-btns d-flex flex-column align-items-center justify-content-center">
              <button class="btn-main w-100 glass mb-3" [routerLink]="['/settings/cart']">
                View Cart
              </button>
              <button class="btn-main w-100" [disabled]="cartItemsNum === 0" routerLink="/checkout">
                Checkout
              </button>
            </div>
          </div>
        </div>
        <div class="icon-item user" (click)="showDropDownUser()">
          <p *ngIf="!user?.avatar">{{ user?.name?.slice(0, 2) }}</p>
          <img *ngIf="user?.avatar" [src]="user?.avatar" [alt]="user?.name" width="33" height="33" />
          <div class="dropdown-nav" *ngIf="showUser" outsideClick (outsideClick)="showUser = false">
            <ul class="user-list list-unstyled">
              <li routerLink="/settings/profile">
                <i class="pi pi-user"></i>
                Profile
              </li>
              <li routerLink="/settings/orders">
                <i class="pi pi-book"></i>
                Orders
              </li>
              <li routerLink="/settings/wallets">
                <i class="pi pi-wallet"></i>
                Wallets
              </li>
              <li routerLink="/settings/tickets">
                <i class="pi pi-ticket"></i>

                Tickets
              </li>
              <li routerLink="/settings/transactions">
                <i class="pi pi-dollar"></i>
                Transactions
              </li>
              <hr />
              <li (click)="logout()">
                <i class="pi pi-sign-out"></i>
                Logout
              </li>
            </ul>
          </div>
        </div>
        <div class="icon-item log " *ngIf="wallets" style="cursor: default;">
          <div class="d-flex align-items-center">
            <img src="../../../../../assets/images/navbar/log.svg" alt="" />
            {{ selectedWallet.balance | number : "1.2" }}
            {{ selectedWallet.currency }}
          </div>
        </div>
      </ng-template>
      <ng-template #notAuth>
        <button class="btn-main position-relative ms-2 glass" (click)="showDropDownLogin()">
          Login
          <div class="dropdown-nav login" *ngIf="showLogin" outsideClick (outsideClick)="showLogin = false">
            <div class="login-list d-flex flex-column align-items-center justify-content-center">
              <img src="assets/images/navbar/drop-downs/login.webp" alt="" width="144" height="146" />
              <button class="btn-main w-100 mb-3" routerLink="auth/signin">
                Login
              </button>
              <button class="btn-main w-100 glass" routerLink="auth/signup">
                Sign Up
              </button>
            </div>
          </div>
        </button>
      </ng-template>
      <ng-container *ngIf="loadingNav && user != null; then auth; else notAuth"></ng-container>
    </div>
  </ng-template>

  <nav class="navbar navbar-expand-md navbar-dark desktop-nav">
    <div [ngClass]="screenWidth > 991 ? 'container' : 'container-fluid'">
      <div class="nav-img">
        <ng-template [ngTemplateOutlet]="logoImg"></ng-template>
      </div>
      <div class="nav-search" [ngClass]="screenWidth > 991 ? 'd-block' : 'd-none'">
        <ng-template [ngTemplateOutlet]="searchNav"></ng-template>
      </div>

      <div class="nav-icons">
        <ng-template [ngTemplateOutlet]="navIcons"></ng-template>
      </div>
    </div>
  </nav>
</section>

<section class="navbar-mobile" *ngIf="screenWidth <= 768">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-3">
        <div class="icons-logo d-flex align-items-center">
          <span class="bar-icon" (click)="listLinksNav = !listLinksNav">
            <i class="pi pi-bars"></i>
          </span>
          <span class="logo-icon" routerLink="/">
            <img src="../../../../../assets/images/navbar/card4games-voucher-mobile.svg" alt="logo icon" width="44"
              height="25" />
          </span>
        </div>
      </div>
      <div class="col-9">
        <div class="icons-logo two d-flex align-items-center justify-content-end">
          <span class="search-icon" routerLink="/search">
            <i class="pi pi-search"></i>
          </span>
          <span class="currency-icon" (click)="currencyListNav = !currencyListNav" *ngIf="!disableCurrency">
            <div class="item d-flex align-items-center">
              <img width="30" height="11" alt="currency icon" src="assets/images/navbar/currency.svg" />
              <p class="currency-kay mb-0 d-flex align-items-center">
                {{ selectedCurrency.key }}
              </p>
            </div>
          </span>
          <ng-template #notAuth>
            <span class="user-icon" (click)="loginListNav = !loginListNav">
              <i class="pi pi-user"></i>
            </span>
          </ng-template>
          <ng-template #auth>
            <span class="user-icon pic" (click)="userListNav = !userListNav">
              <p *ngIf="!user?.avatar">{{ user?.name?.slice(0, 2) }}</p>
              <img *ngIf="user?.avatar" [src]="user?.avatar" [alt]="user?.name" width="33" height="33" />
            </span>
            <span class="user-icon log" *ngIf="wallets" style="cursor: default;">
              <div class="d-flex align-items-center">
                <img src="../../../../../assets/images/navbar/log.svg" alt="" />
                {{ wallets[0]?.balance }} {{ selectedCurrency.key }}
              </div>
            </span>
          </ng-template>
          <ng-container *ngIf="loadingNav && user != null; then auth; else notAuth"></ng-container>
        </div>
      </div>
    </div>
  </div>
</section>

<p-sidebar [(visible)]="listLinksNav" position="left">
  <div class="links-sidebar">
    <div class="row flex-column">
      <div class="col-12">
        <img src="assets/images/navbar/card4games-voucher cards3.svg" alt="card4games logo" width="220" height="70"
          routerLink="/" />
      </div>
      <div class="col-12">
        <ul class="list-links list-unstyled">
          <li routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            (click)="listLinksNav = !listLinksNav">
            <span>Home</span>
          </li>
          <li (click)="listLinksNav = !listLinksNav" *ngFor="let item of mainCategoryList"
            routerLink="/categories/{{ item._id }}" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{ name: item.name }">
            <span>
              {{ item.name }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="currencyListNav" position="right">
  <div class="currency-sidebar">
    <div class="row flex-column">
      <div class="col-12">
        <ul class="currency-list list-unstyled">
          <li *ngFor="let item of currencyList" (click)="updateCurrency(item.currency)">
            {{ item.currency }}
            <img [src]="item.icon" width="20" height="20" [alt]="item.currency" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="loginListNav" position="right">
  <div class="login-list pt-5 d-flex flex-column align-items-center justify-content-center">
    <img src="assets/images/navbar/drop-downs/login.webp" alt="" width="144" height="146" />
    <button class="btn-main w-100 mb-3" routerLink="auth/signin">Login</button>
    <button class="btn-main w-100 glass" routerLink="auth/signup">
      Sign Up
    </button>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="userListNav" position="right">
  <div class="user-list-side h-100">
    <div class="row">
      <div class="col-12">
        <ul class="user-list list-unstyled">
          <li (click)="userListNav = !userListNav">
            <span class="user-icon">
              <p *ngIf="!user?.avatar">{{ user?.name?.slice(0, 2) }}</p>
              <img *ngIf="user?.avatar" [src]="user?.avatar" [alt]="user?.name" width="33" height="33" />
            </span>
            {{ user?.name }}
          </li>

          <li routerLink="/settings/profile" (click)="userListNav = !userListNav">
            <i class="pi pi-user"></i>
            Profile
          </li>
          <li routerLink="/settings/cart" (click)="userListNav = !userListNav">
            <i class="pi pi-shopping-cart"></i>
            Cart
          </li>
          <li routerLink="/settings/orders" (click)="userListNav = !userListNav">
            <i class="pi pi-book"></i>
            Orders
          </li>
          <li routerLink="/settings/wallets" (click)="userListNav = !userListNav">
            <i class="pi pi-wallet"></i>
            Wallets
          </li>
          <li routerLink="/settings/tickets" (click)="userListNav = !userListNav">
            <i class="pi pi-ticket"></i>
            Tickets
          </li>
          <li routerLink="/settings/transactions" (click)="userListNav = !userListNav">
            <i class="pi pi-dollar"></i>
            Transactions
          </li>
          <li routerLink="/settings/notification" (click)="userListNav = !userListNav">
            <i class="pi pi-bell"></i>
            Notification
          </li>
          <li class="log-out position-fixed" (click)="logout()" (click)="userListNav = !userListNav">
            <hr />
            <i class="pi pi-sign-out"></i>
            Logout
          </li>
        </ul>
      </div>
    </div>
  </div>
</p-sidebar>
