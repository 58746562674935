import { Notification } from '../models/interfaces/notification';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { IResponse } from '../models/interfaces/response.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private apiURL = environment.apiUrl;
  private url = `${this.apiURL}notifications`;
  constructor(private http: HttpClient) { }
  getAllNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(this.url, { withCredentials: true });
  }
  getUserNotifications(param: {} = {}): Observable<IResponse<Notification>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<Notification>>(`${this.url}/user-notifications?${params}`, {
      withCredentials: true,
    });
  }
  getNotification(id: string): Observable<Notification> {
    return this.http.get<Notification>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }
  getUnreadNotificationNumber(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(`${this.url}/user-unread-numbers`, {
      withCredentials: true,
    });
  }
  createNotifications(notification: Notification): Observable<Notification> {
    return this.http.post<Notification>(this.url, notification, {
      withCredentials: true,
    });
  }
  createNotificationsForCustomeUser(notification: Notification, id: any): Observable<Notification> {
    return this.http.post<Notification>(`${this.url}/${id}`, notification, {
      withCredentials: true,
    });
  }
  readNotifications(id: string): Observable<Notification> {
    return this.http.patch<Notification>(
      `${this.url}/read/${id}`, {},
      { withCredentials: true }
    );
  }
  deleteNotifications(id: string): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }
  searchUsersByEmail(email: string): Observable<any> {
    return this.http.get<any>(`${this.apiURL}admin/users/search-users-by-email/${email}`, {
      withCredentials: true,
    });
  }
}
