import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  email: string = 'sales@egycards.com';
  constructor() {}

  ngOnInit(): void {}

  scrollUp(): void {
    window.scrollTo(0, 0);
  }
}
