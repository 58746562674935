import {
  MainCategory,
  Category,
  SubCategory,
  SortCategoriesRequest,
  Category1,
  SubCategory1,
  MainCategory1,
} from './../models/category';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { ICount } from '../models/interfaces/count.model';
@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  // Define API
  private apiURL = environment.apiUrl;
  private url = `${this.apiURL}categories/`;
  private urlUser = `${this.apiURL}user-categories/`;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  /*==============================MainCaetgories================================*/
  getMainCategories(queryParam = ''): Observable<any> {
    return this.http.get<any>(
      `${this.url}main-categories${queryParam}`,
      { withCredentials: true }
    );
  }
  getUserMainCategories(queryParam = ''): Observable<any> {
    return this.http.get<any>(
      `${this.urlUser}main-categories${queryParam}`,
      { withCredentials: true }
    );
  }
  getSingleMainCategory(id: any): Observable<MainCategory1> {
    return this.http.get<MainCategory1>(`${this.url}main-categories/${id}`, {
      withCredentials: true,
    });
  }
  createMainCategory(mainObj: MainCategory1): Observable<MainCategory> {
    return this.http.post<MainCategory>(`${this.url}main-categories`, mainObj, {
      withCredentials: true,
    });
  }
  updateMainCategory(newData: any): Observable<MainCategory> {
    return this.http.patch<any>(
      `${this.url}main-categories/${newData._id}`,
      newData,
      { withCredentials: true }
    );
  }
  sortMainCategory(payload: SortCategoriesRequest): Observable<MainCategory[]> {
    return this.http.patch<MainCategory[]>(
      `${this.url}main-categories/sort`,
      payload,
      { withCredentials: true }
    );
  }
  deleteMainCategory(id: any): Observable<MainCategory> {
    return this.http.delete<MainCategory>(`${this.url}main-categories/${id}`, {
      withCredentials: true,
    });
  }
  restoreMainCategory(id: any): Observable<any[]> {
    console.log(id)
    return this.http.patch<any[]>(`${this.url}redeem-main-categories/${id}`, {}, { withCredentials: true });
  }
  /*==============================Caetgories================================*/
  getCategories(queryParam = ''): Observable<Category> {
    return this.http.get<Category>(`${this.url}category${queryParam}`);
  }
  getUserCategories(queryParam = ''): Observable<Category> {
    
    return this.http.get<Category>(`${this.urlUser}category${queryParam}`);
  }
  getSingleCategory(id: any): Observable<Category1> {
    return this.http.get<Category1>(`${this.url}category/${id}`);
  }
  createCategory(categoryObj: Category1): Observable<Category> {
    return this.http.post<Category>(`${this.url}category`, categoryObj, {
      withCredentials: true,
    });
  }
  updateCategory(newData: Category1): Observable<Category> {
    return this.http.patch<Category>(
      `${this.url}category/${newData._id}`,
      newData,
      { withCredentials: true }
    );
  }
  sortCategory(payload: SortCategoriesRequest): Observable<Category[]> {
    return this.http.patch<Category[]>(`${this.url}categories/sort`, payload, {
      withCredentials: true,
    });
  }
  deleteCategory(id: any): Observable<Category> {
    return this.http.delete<Category>(`${this.url}category/${id}`, {
      withCredentials: true,
    });
  }
  restoreCategory(id: any): Observable<any[]> {
    return this.http.patch<any[]>(
      `${this.url}redeem-category/${id}`,
      {},
      { withCredentials: true }
    );
  }
  /*==============================SubCaetgories================================*/
  getSubCategories(queryParam = ''): Observable<SubCategory> {
    // Encode the parameter
    // const encodedQueryParam = encodeURIComponent(queryParam); // This will turn 'pzubj++p' into 'pubj%2B%2Bp'

    // Set up query parameters
    // const params = new HttpParams().set('name', encodedQueryParam);
    // const params = new HttpParams().set('name', queryParam);
    // return this.http.get<SubCategory>(`${this.url}sub-categories`, { params });
    return this.http.get<SubCategory>(`${this.url}sub-categories/${queryParam}`);
  }
  getUserSubCategories(queryParam = ''): Observable<SubCategory> {
    return this.http.get<SubCategory>(`${this.urlUser}sub-categories/${queryParam}`);
  }
  getSingleSubCategories(id: any): Observable<SubCategory1> {
    return this.http.get<SubCategory1>(`${this.url}sub-categories/${id}`);
  }
  createSubCategory(subCatyObj: SubCategory1): Observable<SubCategory> {
    return this.http.post<SubCategory>(
      `${this.url}sub-categories`,
      subCatyObj,
      { withCredentials: true }
    );
  }
  updateSubCategory(newData: SubCategory1): Observable<SubCategory> {
    return this.http.patch<SubCategory>(
      `${this.url}sub-categories/${newData._id}`,
      newData,
      { withCredentials: true }
    );
  }
  sortSubCategory(payload: SortCategoriesRequest): Observable<SubCategory[]> {
    return this.http.patch<SubCategory[]>(
      `${this.url}sub-categories/sort`,
      payload,
      { withCredentials: true }
    );
  }
  deleteSubCategory(id: any): Observable<SubCategory> {
    return this.http.delete<SubCategory>(`${this.url}sub-categories/${id}`, {
      withCredentials: true,
    });
  }
  restoreSubCategory(id: any): Observable<any[]> {
    console.log('in serv')
    return this.http.patch<any[]>(`${this.url}redeem-sub-categories/${id}`, {}, { withCredentials: true });
  }
  getCount(name: string, id: string | number = ''): Observable<ICount<string>[]> {
    return this.http.get<ICount<string>[]>(`${this.url}count/${name}?referenceId=${id}`, { withCredentials: true }).pipe(
      catchError((err) => {
        throw new Error(err.message);
      })
    );
  }
}
