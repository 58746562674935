import { ProductType } from './../models/enums/productTypes.enum';
import { Subject, Observable, BehaviorSubject, ReplaySubject, AsyncSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Order } from '../models/order';
import { ILicenseCount } from '../models/interfaces/ILicenseCount';
import {

  Exchange,
  IActiveCurrency,
} from '../models/interfaces/exchange.model';
import { IUser } from '../models/interfaces/user.model';
import { ICart } from '../models/interfaces/cart.model';

@Injectable({
  providedIn: 'root',
})
export class StoreChangesService {
  productType = ProductType;
  FA_AUTH = new Subject<boolean>();
  activeExchange: BehaviorSubject<Exchange> = new BehaviorSubject<Exchange>({
    EGP: 1,
    EUR: 1,
    AED: 1,
    SAR: 1,
    USD: 1,
  });
  VALIDATE_USER = new Subject<IUser>();
  cart = new BehaviorSubject<ICart | null>(null);
  runningOrder = new BehaviorSubject<Order>({});
  private avter = new BehaviorSubject<any>({});
  private logOutFromProfile = new BehaviorSubject<boolean>(false);
  private loginMessage = new BehaviorSubject<any>('');
  private cartItemNumber = new Subject<number | any>();
  private balance = new BehaviorSubject<number>(0);
  private CategoryZeroLenght = new BehaviorSubject<string>('');
  private selectedWallet = new BehaviorSubject<any>({});
  private USER = new BehaviorSubject<IUser | any>(null);
  private mainCategoryFromParent = new BehaviorSubject<IUser | any>({});
  private CategoryFromParent = new BehaviorSubject<IUser | any>({});
  private LoginSecret = new BehaviorSubject<string>('');
  private activatedCurrency = new BehaviorSubject<IActiveCurrency>({
    key: 'USD',
    value: 1,
  });
  private userData!: IUser;
  private licensesChanges = new BehaviorSubject<boolean>(false);
  private readNotificationsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor() { }

  setUser(user: IUser | any) {
    this.USER.next(user);
  }

  getUserData() {
    return this.userData;
  }

  pushNewLicenses(licenses: any[]) { }

  setLicensesChanges(change: boolean) {
    this.licensesChanges.next(change);
  }

  getLicensesChanges() {
    return this.licensesChanges.asObservable();
  }
  setMainCategoryFromParent(mainCategoryFromParent: any) {
    this.mainCategoryFromParent.next(mainCategoryFromParent);
  }

  getMainCategoryFromParent() {
    return this.mainCategoryFromParent.asObservable();
  }
  setCategoryFromParent(CategoryFromParent: any) {
    this.CategoryFromParent.next(CategoryFromParent);
  }

  getCategoryFromParent() {
    return this.CategoryFromParent.asObservable();
  }
  setloginMessage(message: any) {
    this.loginMessage.next(message);
    setTimeout(() => {
      this.loginMessage.next('');
    }, 400)
  }

  getloginMessage() {
    return this.loginMessage.asObservable();
  }
  setlogOutFromProfile(confirm: any) {
    this.logOutFromProfile.next(confirm);
    // setTimeout(() => {
    //   this.logOutFromProfile.next('');
    // }, 400)
  }

  getlogOutFromProfile() {
    return this.logOutFromProfile.asObservable();
  }

  // getUser(): Observable<IUser> {
  //   console.log(this.USER)
  //   return this.USER.asObservable();
  // }
  getUser(): Observable<IUser> {
    const lastUser = this.USER.getValue();
    if (lastUser) {
      return of(lastUser);
    } else {
      return this.USER.asObservable();
    }
  }
  getUserAdmin(): Observable<IUser> {
    return this.USER.asObservable();
  }

  setAvter(Avter: any) {
    this.avter.next(Avter);
  }
  getAvter(): Observable<any> {
    return this.avter.asObservable();
  }
  setRunningOrder(OrderId: any) {
    this.runningOrder.next(OrderId);
  }

  getRunningOrder(): Observable<any> {
    return this.runningOrder.asObservable();
  }

  setCartNumber(number: number) {
    this.cartItemNumber.next(number);
  }
  getCartNumber(): Observable<number> {
    return this.cartItemNumber.asObservable();
  }
  setSelectedWallet(selectedWallet: {}) {
    this.selectedWallet.next(selectedWallet);
  }
  getSelectedWallet(): Observable<any> {
    return this.selectedWallet.asObservable();
  }
  setBalance(number: number) {
    this.balance.next(number);
  }
  getBalance(): Observable<number>  {
    return this.balance.asObservable();
    // const lastbalance = this.balance.getValue();
    // if (lastbalance !== 0) {
    //   return this.balance.asObservable()
    // }
    // console.log(lastbalance)
    // if (lastbalance = 0) {
    //   return of(lastbalance);
    // } else {
    //   return this.balance.asObservable();
    // }
  }
  set2FA(active: boolean) {
    this.FA_AUTH.next(active);
  }
  get2FA(): Observable<boolean> {
    return this.FA_AUTH.asObservable();
  }

  setExchange(exchange: Exchange) {
    this.activeExchange.next(exchange);
  }
  getExchange(): Observable<Exchange> {
    return this.activeExchange.asObservable();
  }
  setLoginSecret(secret: string) {
    this.LoginSecret.next(secret);
  }
  getLoginSecret(): Observable<string> {
    return this.LoginSecret.asObservable();
  }
  setActivatedCurrency(newCurrency: IActiveCurrency) {
    this.activatedCurrency.next(newCurrency);
  }
  getActivatedCurrency(): Observable<IActiveCurrency> {
    return this.activatedCurrency.asObservable();
  }
  setCart(cart: ICart) {
    console.log(cart)
    this.cart.next(cart);
  }
  getCart(): Observable<ICart | null> {
    return this.cart.asObservable();
  }
  setReadNotificationsCount(count: number) {
    this.readNotificationsCount.next(count);
  }
  getReadNotificationsCount(): Observable<number> {
    return this.readNotificationsCount.asObservable();
  }
  setCategoryZeroLenght(id: string) {
    this.CategoryZeroLenght.next(id);
  }
  getsetCategoryZeroLenght(): Observable<string> {
    return this.CategoryZeroLenght.asObservable();
  }
}
