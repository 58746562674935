<section class="refund-policy">
  <div class="container position-relative">
    <h2 class="title-footer">Refund Policy</h2>
    <div class="row">
      <div class="col-md-12">
        <p class="desc mb-0">
          Once you ordered and we sent the code for you, we can not cancel or
          issue a refund for you or exchange the product, but we guarantee that
          all codes are fresh and working without any problems, If you face any
          problems when redeeming the codes we will help you to redeem them or
          exchange it or issue a full refund for you All code is region
          restricted so you must choose the correct region and enter the correct
          information Some of codes have a expire date. If you have any problem
          with the code you must send us in the first 24hours so we can check it
          with the supplier . if you have any problem we are happy to help you
          24/7, you can chat with us through our channels
        </p>
        <br />

        <div style="color: white">
          <li>Facebook page: https://www.facebook.com/Card4GamesLTD/</li>
          <li>send email: sales@card4games.com</li>
          <li>whats up on: +966563089634</li>
        </div>
      </div>
    </div>
  </div>
</section>
