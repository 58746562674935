import { SendNote } from './../models/note';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from './../services/user.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import * as userActions from '../actions/user.action';
import { Note } from '../models/note';
@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private authService: AuthService,
    private userService: UserService
  ) { }

  public loadAllUsers$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.GET_ALL_USERS.type),
    mergeMap((action: userActions.ActionWithPayload<string>) => {
      return this.userService.getAllUsers(action.payload).pipe(
        retry(1),
        map((response) => userActions.GET_ALL_USERS_SUCCESS({ payload: response })),
        catchError((error) => of(userActions.GET_ALL_USERS_FAIL({ payload: error })))
      );
    })
  ));

  public signUpEffect$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.SIGNUP.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.signUp(action.payload).pipe(
        retry(1),
        map((response) => { return userActions.SIGNUP_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.SIGNUP_FAIL({ payload: error }))),
      );
    }),
  ));
  public signInEffect$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.SIGNIN.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.signIn(action.payload).pipe(
        retry(1),
        map((response) => {
          return userActions.SIGNIN_SUCCESS({ payload: response });
        }),
        catchError((error) => of(userActions.SIGNIN_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorSignIn$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.SIGNIN_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => {
      return action.payload
    })),
    { dispatch: false },
  );
  public changePassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.CHANGE_PASSWORD.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.changePassword(action.payload).pipe(
        retry(1),
        map((response) => { return userActions.CHANGE_PASSWORD_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.CHANGE_PASSWORD_FAIL({ payload: error }))),
      );
    }),
  ));
  public verfiyEmail$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VERIFY_EMAIL.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.verifyEmail(action.payload).pipe(
        retry(1),
        map((response) => { return userActions.VERIFY_EMAIL_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.VERIFY_EMAIL_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorChangePassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.CHANGE_PASSWORD.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  public errorVerifyEmail$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VERIFY_EMAIL_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => {
      return action.payload
    })),
    { dispatch: false },
  );
  public verfiyID$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VERIFY_ID.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.verifyID(action.payload).pipe(
        retry(1),
        map((response) => { return userActions.VERIFY_ID_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.VERIFY_ID_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorVerifyID$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VERIFY_ID_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );
  public set2FA$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.SET2FA.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.set2FA(action.payload).pipe(
        retry(1),
        map((response) => {
          return userActions.SET2FA_SUCCESS({ payload: response });
        }),
        catchError((error) => of(userActions.SET2FA_FAIL({ payload: error }))),
      );
    }),
  ));

  public verfiy2FA$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VERIFY_2FA.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.verify2FA(action.payload).pipe(
        retry(1),
        map((response) => {
          return userActions.VERIFY_2FA_SUCCESS({ payload: response });
        }),
        catchError((error) => of(userActions.VERIFY_2FA_FAIL({ payload: error }))
        ),
      );
    }),
  ));

  public errorVerify2FA$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VERIFY_2FA.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );

  public activate2FA$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.ACTIVATE_2FA.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.activate2FA(action.payload).pipe(
        retry(1),
        map((response) => {
          return userActions.ACTIVATE_2FA_SUCCESS({ payload: response });
        }),
        catchError((error) => of(userActions.ACTIVATE_2FA_FAIL({ payload: error }))
        ),
      );
    }),
  ));

  public errorActivate2FA$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.ACTIVATE_2FA.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );

  public forgetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.FORGET_PASSWORD.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.forgotPassword(action.payload).pipe(
        retry(1),
        map((response) => {
          return userActions.FORGET_PASSWORD_SUCCESS({ payload: response });
        }),
        catchError((error) => of(userActions.FORGET_PASSWORD_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorFrogetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.FORGET_PASSWORD_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  public resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.RESET_PASSWORD.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.resetPassword(action.payload.token, action.payload.password).pipe(
        retry(1),
        map((response) => { return userActions.RESET_PASSWORD_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.RESET_PASSWORD_FAIL({ payload: error }))),
      );
    }),
  ));

  public logout$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.LOGOUT.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.logout().pipe(
        retry(1),
        map((response) => { return userActions.LOGOUT_SUCCESS(); }),
        catchError((error) => of(userActions.LOGOUT_FAIL())),
      );
    }),
  ));
  public errorOnChangePassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.CHANGE_PASSWORD_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public resendEmail$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.RESEND_EMAIL.type),
    mergeMap((action: userActions.ActionWithPayload<string>) => {
      return this.authService.resendEmail(action.payload).pipe(
        retry(1),
        map((response) => { return userActions.RESEND_EMAIL_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.RESEND_EMAIL_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnResendEmail$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.RESEND_EMAIL_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  public validate$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VALIDATE.type),
    mergeMap((action: userActions.ActionWithPayload<any>) => {
      return this.authService.validate().pipe(
        retry(1),
        map((response) => { return userActions.VALIDATE_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.VALIDATE_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnValidate$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.VALIDATE_FAIL.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  public addUserNote$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.ADD_USER_NOTE.type),
    mergeMap((action: userActions.ActionWithPayload<SendNote>) => {
      return this.userService.addUserNote(action.payload).pipe(
        retry(1),
        map((response) => { return userActions.ADD_USER_NOTE_SUCCESS({ payload: response }); }),
        catchError((error) => of(userActions.ADD_USER_NOTE_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnAddUserNote$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.ADD_USER_NOTE.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  // public updateUser$ = createEffect(() => this.actions$.pipe(
  //   ofType(userActions.UPDATE_USER.type),
  //   mergeMap((action: userActions.ActionWithPayload<User>) => {
  //     return this.userService.updateUser(action.payload).pipe(
  //       retry(1),
  //       map((response) => { return userActions.UPDATE_USER_SUCCESS({ payload: response }); }),
  //       catchError((error) => of(userActions.UPDATE_USER_FAIL({ payload: error }))),
  //     );
  //   }),
  // ));
  public errorOnUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.UPDATE_USER.type),
    tap((action: userActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
}
