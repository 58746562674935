import { FAQstoreProvider } from './../../../../../../shared/providers/media/faq.provider';
import { Component, OnInit } from '@angular/core';
import { FAQ } from '../../../../../../shared/models/media';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {
  faqList: FAQ[] = [];
  constructor(private faqStore: FAQstoreProvider) { }

  ngOnInit(): void {
    this.getFAQs();
  }
  getFAQs() {
    this.faqStore.getAllFAQs$().subscribe((response) => {
      this.faqList = response;
    })
  }
}
