import { Blog } from './../../models/media';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap} from 'rxjs/operators';
import * as blogActions from '../../actions/media.action';
import { MediaService } from '../../services/media.service';
@Injectable()
export class BlogEffects {
  constructor(private actions$: Actions, private mediaService: MediaService) { }

  public getAllBlogs$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.GET_BLOGS.type),
    mergeMap((action: blogActions.ActionWithPayload<any>) => {
      return this.mediaService.getBlogs(action.payload).pipe(
        retry(1),
        map((response) =>{return blogActions.GET_BLOGS_SUCCESS({ payload: response })}),
        catchError((error) => of(blogActions.GET_BLOGS_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorGetAllBlogs$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.GET_BLOGS_FAILED.type),
    tap((action: blogActions.ActionWithPayload<HttpErrorResponse>) => {return action.payload})),
    { dispatch: false },
  );
  public getBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.GET_BLOG.type),
    mergeMap((action: blogActions.ActionWithPayload<any>) => {
      return this.mediaService.getBlog(action.payload).pipe(
        retry(1),
        map((response) =>{return blogActions.GET_BLOG_SUCCESS({ payload: response })}),
        catchError((error) => of(blogActions.GET_BLOG_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorGetBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.GET_BLOG_FAILED.type),
    tap((action: blogActions.ActionWithPayload<HttpErrorResponse>) => {return action.payload})),
    { dispatch: false },
  );
  public createBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.CREAT_BLOG.type),
    mergeMap((action: blogActions.ActionWithPayload<Blog>) => {
      return this.mediaService.createBlog(action.payload).pipe(
        retry(1),
        map((response:Blog) =>{return blogActions.CREAT_BLOG_SUCCESS({ payload: response })}),
        catchError((error) => of(blogActions.CREAT_BLOG_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorCreateBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.GET_BLOGS_FAILED.type),
    tap((action: blogActions.ActionWithPayload<HttpErrorResponse>) => {return action.payload})),
    { dispatch: false },
  );
  public updateBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.UPDATE_BLOG.type),
    mergeMap((action: blogActions.ActionWithPayload<Blog>) => {
      return this.mediaService.updateBlog(action.payload).pipe(
        retry(1),
        map((response:Blog) =>{return blogActions.UPDATE_BLOG_SUCCESS({ payload: response })}),
        catchError((error) => of(blogActions.UPDATE_BLOG_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorUpateBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.UPDATE_BLOG_FAILED.type),
    tap((action: blogActions.ActionWithPayload<HttpErrorResponse>) => {return action.payload})),
    { dispatch: false },
  );
  public deleteBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.DELETE_BLOG.type),
    mergeMap((action: blogActions.ActionWithPayload<string>) => {
      return this.mediaService.deleteBlog(action.payload).pipe(
        retry(1),
        map((response:Blog) =>{return blogActions.DELETE_BLOG_SUCCESS({ payload: response })}),
        catchError((error) => of(blogActions.DELETE_BLOG_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorDeleteBlog$ = createEffect(() => this.actions$.pipe(
    ofType(blogActions.DELETE_BLOG_FAILED.type),
    tap((action: blogActions.ActionWithPayload<HttpErrorResponse>) => {return action.payload})),
    { dispatch: false },
  );
}