<section class="terms">
  <div class="container position-relative">
    <div class="terms-container">
      <h1>Terms & Conditions</h1>
      <p>Welcome to Game4game store,</p>
      <p>DYJTAL KWD PORTAL LLC maintains the Store Card4game.com.</p>
      <p>Please read the following terms and conditions:</p>

      <h2>ABOUT THE WEBSITE</h2>
      <p>The United Arab Emirates is our country of domicile and stipulates that the governing law is the local law.</p>
      <p>We do not trade with or provide services to OFAC and sanctioned countries listed by Visa, MasterCard, or
        schemes accepted.</p>
      <p>Customers using the website who are minors (under the age of 18) shall not register as a User of the website
        and shall not transact or use the website.</p>
      <p>The user is responsible for maintaining the confidentiality of their account.</p>
      <p>Cardholders must retain a copy of transaction records and merchant policies and rules.</p>
      <p>The Website is an e-commerce platform allowing users to purchase various game cards and digital services.
        GameCardi reserves the right to introduce new services, update or withdraw any services at our discretion
        without any liability.</p>

      <h2>Client’s Obligations</h2>
      <p>You pledge, represent, and guarantee that:</p>
      <ul>
        <li>All applicable laws, statutes, and regulations, including all privacy laws and content regulations, will
          always be complied with.</li>
        <li>You have full capacity and authority to enter into these Terms of Use.</li>
        <li>If you create or use an account on behalf of a business entity, you have the authority to bind the business.
        </li>
      </ul>

      <h2>Data Collection and Usage</h2>
      <p>We collect personal data for purposes such as executing purchase agreements, facilitating delivery, and
        handling payment settlements.</p>
      <p>Personal data we may collect includes:</p>
      <ul>
        <li>Name</li>
        <li>Address</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Payment details</li>
        <li>IP address</li>
        <li>Device Fingerprint</li>
      </ul>

      <h2>WARRANTIES, REPRESENTATIONS & UNDERTAKINGS</h2>
      <p>In no event will we, our parent company, subsidiaries, affiliates, and our directors, officers, agents,
        employees, or suppliers be liable for loss of profits, data, or other monetary loss, even if we have been
        negligent.</p>
      <p>You agree to indemnify us and hold us harmless from any losses, damages, and expenses arising out of or related
        to your use of our services.</p>

      <h2>LIABILITY & INDEMNITIES</h2>
      <p>In no event will we, our parent company, subsidiaries, affiliates, and our directors, officers, agents,
        employees, or suppliers be liable for special, indirect, incidental, or consequential damages.</p>

      <h2>REPORTING VIOLATIONS OF THESE TERMS OF USE</h2>
      <p>If you believe any listed items or materials violate our terms of use, please report them to us.</p>

      <h2>Communications</h2>
      <p>You may contact us through email at <a href="mailto:sales@card4games.com">sales@Card4game.com</a>.</p>
      <div class="rtl">
        <h2>سياسات الخدمه / شروط الاستخدام</h2>
        <p>بالتسجيل على موقعنا فأنت توافق على استلام رسائل على الإيميل أو رقمك المسجل لدينا.</p>
        <ul>
          <li>في حالة الشك في عملية الشراء يحق لنا أن نطلب بعض الوثائق الإضافية للتحقق.</li>
          <li>لابد أن تقوم بالدفع بالبطاقة الائتمانية الخاصة بك شخصيًا وليس بطاقة شخص آخر.</li>
          <li>يقر المستخدم بأن معاملات الدفع غير قابلة للاسترداد مادام الكود صالحًا للعمل وصحيحًا.</li>
          <li>مسؤوليتك عن الحفاظ على خصوصيتك هي أمر خاص بك.</li>
        </ul>

      </div>
      <h2>Contact Us</h2>
      <p>For inquiries, please contact us at <a href="mailto:sales@card4game.com">sales@Card4game.com</a> or
        +966552033409.</p>
    </div>
  </div>
</section>