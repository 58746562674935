import { Note, SendNote } from './../models/note';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment as env } from '../environments/environment';
import { IUser } from '../models/interfaces/user.model';
import { IResponse } from '../models/interfaces/response.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiURL = `${env.apiUrl}/users`;
  adminApiURL = `${env.apiUrl}admin/users`;
  apiURLProfile = `${env.apiUrl}auth`;
  constructor(private http: HttpClient) {}
  getAllUsers(param: {} = {}): Observable<IResponse<IUser>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<IUser>>(`${this.adminApiURL}?${params}`, {
      withCredentials: true,
    });
  }
  getUserById(userId: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.adminApiURL}/${userId}`, {
      withCredentials: true,
    });
  }
  updateUser(user: IUser): Observable<IUser> {
    return this.http
      .patch<IUser>(`${this.adminApiURL}/${user._id}`, user, {
        withCredentials: true,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  addUserNote(note: SendNote): Observable<IUser> {
    return this.http.post<IUser>(
      `${this.adminApiURL}/add-user-note/${note.userId}`,
      note.note,
      { withCredentials: true }
    );
  }
  updateProfile(user: any): Observable<IUser> {
    return this.http.patch<IUser>(`${this.apiURLProfile}/update-profile`, user, {
      withCredentials: true,
    });
  }
  searchUsersByEmail(email: string): Observable<any> {
    return this.http.get<any>(`${email}`, {withCredentials: true,});
  }
}
