import { Count } from './../models/order';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BehaviorSubject, Observable, Subject, catchError, of } from 'rxjs';
import {
  ICreateLicensesRequest,
  IDeleteLicensesRequest,
  LicenseRes,
  Licenses,
  License,
} from '../models/license';
import { ProductType } from '../models/enums/productTypes.enum';
import { ICount } from '../models/interfaces/count.model';
import { IResponse } from '../models/interfaces/response.model';
import { IProduct, IProductsLicensesCounts } from '../models/interfaces/product.model';
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  // Define API
  private apiURL = environment.apiUrl;
  private url = `${this.apiURL}products`;
  private licensesUrl = `${this.apiURL}licenses`;
  private IdLicenses = new BehaviorSubject<any>('');
  constructor(private http: HttpClient) { }
  getProducts(param = {}): Observable<IResponse<IProduct>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<IProduct>>(`${this.url}?${params}`, {
      withCredentials: true,
    });
  }
  getProductsCountsLicenses(param = {}): Observable<IResponse<IProductsLicensesCounts>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<IProductsLicensesCounts>>(`${this.url}/count-license?${params}`, {
      withCredentials: true,
    });
  }

  getSingleProduct(id: string): Observable<IProduct> {
    return this.http.get<IProduct>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }

  createProduct(product: IProduct): Observable<IProduct> {
    let kind;
    switch (product.kind) {
      case ProductType.range:
        kind = 'range';
        break;
      case ProductType.voucher:
        kind = 'voucher';
        break;
      default:
        kind = 'topup';
    }
    console.log(this.toServerModel(product!))
    return this.http.post<IProduct>(`${this.url}?kind=${kind}`, this.toServerModel(product!), { withCredentials: true }
    );
  }

  updateProduct(newData: IProduct): Observable<IProduct> {
    return this.http.patch<IProduct>(
      `${this.url}/${newData._id}`,
      this.toServerModel(newData),
      {
        withCredentials: true,
      }
    );
  }

  deleteProduct(id: string): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }

  restoreProduct(productId: string): Observable<any> {
    return this.http.patch<IProduct>(
      `${this.url}/redeem/${productId}`,
      {},
      { withCredentials: true }
    );
  }
  // License Services
  getLicenses(param: {} = {}): Observable<IResponse<License>> {
    const params = new HttpParams({ fromObject: param });
    return this.http
      .get<IResponse<License>>(`${this.licensesUrl}?${params}`, {
        withCredentials: true,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getLicense(licenseId: any): Observable<Licenses> {
    return this.http.get<Licenses>(`${this.licensesUrl}/${licenseId}`, {
      withCredentials: true,
    });
  }

  createLicenses(
    productLicenses: ICreateLicensesRequest
  ): Observable<LicenseRes[]> {
    return this.http.post<LicenseRes[]>(
      `${this.licensesUrl}`,
      productLicenses.licences,
      { withCredentials: true }
    );
  }
  deleteLicenses(licenseId: string): Observable<any> {
    return this.http.delete(
      `${this.licensesUrl}/${licenseId}`,
      { withCredentials: true }
    );
  }
  countLicenses(productId: any): Observable<Count[]> {
    return this.http.get<Count[]>(
      `${this.licensesUrl}/count-licenses/${productId}`,
      { withCredentials: true }
    );
  }

  getUserLicense(licenseId: string): Observable<Licenses> {
    return this.http
      .get<Licenses>(`${this.licensesUrl}/user-license/${licenseId}`, {
        withCredentials: true,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  useUserLicense(licenseId: string): Observable<Licenses> {
    return this.http
      .patch<Licenses>(
        `${this.licensesUrl}/user-license/use-license/${licenseId}`,
        {},
        { withCredentials: true }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  getCount(): Observable<ICount<string>[]> {
    return this.http
      .get<ICount<string>[]>(`${this.url}/count`, { withCredentials: true })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getLicensesCount(productId: string): Observable<{ count: ICount<string>[] }> {
    return this.http
      .get<{ count: ICount<string>[] }>(`${this.licensesUrl}/license-count/${productId}`, { withCredentials: true })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  setIdLicenses(id: string) {
    this.IdLicenses.next(id);
  }
  getIdLicenses(): Observable<string> {
    const IdLicenses = this.IdLicenses.getValue();
    // if (IdLicenses) {
    //   return of(IdLicenses);
    // } else {
      return this.IdLicenses.asObservable();
    // }
    // return this.IdLicenses.asObservable();
  }

  toServerModel(entity: IProduct): any {
    if (entity.kind === ProductType.voucher) {
      return {
        name: entity.name,
        price: entity.price,
        costPrice: entity.costPrice,
        onSalePrice: entity.onSalePrice,
        mainCategory: entity.mainCategory?._id,
        category: entity.category?._id,
        subCategory: entity.subCategory?._id,
        isFeatured: entity.isFeatured,
        fees: entity.fees,
        rankDisc: entity.rankDisc,
        kind: entity.kind,
        onSale: entity.onSale,
        inStock: entity.inStock,
        buyLimit: entity.buyLimit,
        licenceStock: 0,
      };
    }
    if (entity.kind === ProductType.topUp) {
      return {
        name: entity.name,
        price: entity.price,
        costPrice: entity.costPrice,
        onSalePrice: entity.onSalePrice,
        mainCategory: entity.mainCategory?._id,
        category: entity.category?._id,
        subCategory: entity.subCategory?._id,
        isFeatured: entity.isFeatured,
        fees: entity.fees,
        rankDisc: entity.rankDisc,
        kind: entity.kind,
        onSale: entity.onSale,
        inStock: entity.inStock,
        inputsInfo: entity.inputsInfo,
        buyLimit: entity.buyLimit,
      };
    }
    if (entity.kind === ProductType.range) {
      return {
        name: entity.name,
        mainCategory: entity.mainCategory?._id,
        category: entity.category?._id,
        subCategory: entity.subCategory?._id,
        isFeatured: entity.isFeatured,
        fees: entity.fees,
        kind: entity.kind,
        onSale: entity.onSale,
        inStock: entity.inStock,
        rangeLimit: entity.rangeLimit,
        buyLimit: entity.buyLimit,
        rangeFees: entity.rangeFees,
        rangeCost: entity.rangeCost,
      };
    }
  }
}
