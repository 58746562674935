<!-- <div style="padding: 21px">
  <body>
    <div class="container">
      <div class="accordion" id="accordionExample">
        <div class="card" >
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <a
                class="btn btn-link btn-block text-left"
                data-toggle="collapse"
                href="#List-{{ i }}"
              >
                <i
                  class="bi bi-patch-question-fill"
                  style="font-size: 125%"
                ></i>
                {{ faq.title }} #{{ i + 1 }}
              </a>
            </h2>
          </div>

          <div
            id="List-{{ i }}"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body" ></div>
          </div>
        </div>
      </div>
    </div>
  </body>
</div>
 -->

<section class="faq">
  <div class="container position-relative">
    <h2 class="title-footer">
      FAQ
    </h2>
    <div class="row">
      <div class="col-md-12">
        <p-accordion [activeIndex]="0">
          <p-accordionTab *ngFor="let faq of faqList; let i = index">
            <ng-template pTemplate="header">
              <span class="title-accordion"> {{faq.title}}</span>
            </ng-template>

            <p class="desc-accordion mb-0" [innerHTML]="faq.topic | safehtml">

            </p>
          </p-accordionTab>

        </p-accordion>
      </div>
    </div>
  </div>
</section>
